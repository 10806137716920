import React, { useEffect } from "react";
import "../../styles/Section2.scss";
import PhoneImage1 from "../../static/assets/PhoneImage1.png";
import PhoneImage2 from "../../static/assets/PhoneImage2.png";
import PhoneImage3 from "../../static/assets/PhoneImage3.png";
import PhoneImage4 from "../../static/assets/PhoneImage4.png";

const phoneImage1AltText =
  "Mobile app screen from GRID showing a map of New York City with markers for restaurants and event venues in West Village, Greenwich Village, and East Village. Locations such as Malaparte, Tartine, The Smith, and Sofar Sounds East Village are shown. The app has a navigation bar at the top with 'Discover' and 'Active' options, and bottom navigation icons for GRID, MATCH, MESSAGE, DATE, and PROFILE. The map uses Mapbox with interactive zoom buttons, and the current time is 12:17.";
const phoneImage2AltText =
  "Mobile app screen from GRID showing a match profile for a user named Steph, 26, with a heart icon for liking the profile. The profile indicates Steph's height as 5'8'' and displays her name and age along with a demo badge. The background shows Steph posing in a white dress outdoors in front of a white fence. The top of the screen shows a message 'Matching at 4 places,' and the bottom navigation includes icons for GRID, MATCH, MESSAGE, DATE, and PROFILE. The current time displayed is 12:11.";
const phoneImage3AltText =
  "Mobile app screen from GRID showing a restaurant reservation page for Malaparte in the West Village. The top part of the screen displays an image of a pizza with arugula and prosciutto. Below, there are options to call, visit the website, or reserve a table. Several food and drink emojis indicate available types of service. A section for 'Available Reservations' offers various time slots to select from. The bottom of the screen has 'Back' and 'Continue' buttons, and the current time displayed is 12:12.";
type InfoTextInput = {
  title_text: string;
  body_text: string;
};
const phoneImage4AltText =
  "Mobile app screen from GRID showing a message conversation with a user named Jason. The conversation begins with Jason asking about favorite Italian dishes, followed by a suggestion to go to Malaparte. The message indicates Jason asked for a date at Malaparte, and the user confirmed it. Additionally, the user asked Jason to go to Sofar Sounds East Village. The top of the screen shows match details, and the bottom includes a text input field. Navigation icons at the bottom allow access to GRID, MATCH, MESSAGE, DATE, and PROFILE. The current time displayed is 12:15.";

const Section2Info = ({ title_text, body_text }: InfoTextInput) => {
  return (
    <div className="section2-info-wrapper">
      <div className="section2-info">
        <h3 className="section2-info-title">{title_text}</h3>
        <p className="section2-info-body">{body_text}</p>
      </div>
    </div>
  );
};

const Section2 = () => {
  return (
    <div className="section2-wrapper">
      <h2 className="section2-title">How it works</h2>
      <div className="section2-body">
        <div className="section2-main">
          <div className="section2-row">
            <Section2Info
              title_text="Find places or events."
              body_text="Whether it’s a bar, coffee shop, restaurant, or show, it’s up to you where you want your first date to be. Using our interactive map or list view, select the places or events you’d like to go to."
            />
            <div className="section2-row-image-wrapper">
              <img
                className="section2-row-image"
                src={PhoneImage1}
                alt={phoneImage1AltText}
              />
            </div>
          </div>

          <div className="section2-row row-reverse">
            <Section2Info
              title_text="Find a date."
              body_text="See people who only want to go to the same places as you. Then swipe right on who you’re into (you know the drill)."
            />
            <div className="section2-row-image-wrapper">
              <img
                className="section2-row-image"
                src={PhoneImage2}
                alt={phoneImage2AltText}
              />
            </div>
          </div>

          <div className="section2-row">
            <Section2Info
              title_text="See and make reservations."
              body_text="Book reservations without leaving the app. You and your match can view availability for the best local spots."
            />
            <div className="section2-row-image-wrapper">
              <img
                className="section2-row-image"
                src={PhoneImage3}
                alt={phoneImage3AltText}
              />
            </div>
          </div>

          <div className="section2-row row-reverse">
            <Section2Info
              title_text="Then go."
              body_text="Message your match to plan your date, then save the chit-chat for IRL."
            />
            <div className="section2-row-image-wrapper">
              <img
                className="section2-row-image"
                src={PhoneImage4}
                alt={phoneImage4AltText}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section2;
