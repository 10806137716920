import React, { useState, useEffect } from "react";
import "../../../styles/Section1.scss";
import GridTextLogo from "../../../static/assets/grid-text-logo.png";
import AppStoreLink from "../../../static/assets/appstorelink.png";
import SoDoesHe from "../../../static/assets/SoDoesHe.png";
import SoDoesShe from "../../../static/assets/SoDoesShe.png";
// import GridDownloadOnTheAppStore from "../../../static/assets/DownloadOnTheAppStore.png";
import GridDownloadOnTheAppStore from "../../../static/assets/DownloadOnAppStore.png";

const appStoreUrl =
  "https://apps.apple.com/app/apple-store/id6474217376?pt=126655796&ct=website&mt=8";

const Section1 = () => {
  const [randomImage, setRandomImage] = useState("");

  useEffect(() => {
    // Randomly choose between SoDoesHe and SoDoesShe with 50% chance each
    const chosenImage = Math.random() < 0.5 ? SoDoesHe : SoDoesShe;
    setRandomImage(chosenImage);
  }, []);

  const scrollDown = () => {
    // Calculate the pixel value for 340vh
    const scrollAmount = document.body.scrollHeight - window.innerHeight;
    document.body.scrollTo({ top: scrollAmount, left: 0, behavior: "smooth" });
  };

  return (
    <div className="section1-wrapper">
      <div className="section1-sub1-wrapper">
        <div className="section1-sub1-content">
          {/* <img id="GridTextLogo" src={GridTextLogo} alt="Grid" /> */}
          <h1 id="section1-sub1-title">Grid Dating App</h1>
          <h2 id="section1-sub1-subtitle">Match by Place</h2>
          <p id="section1-sub1-description">
          Where you choose to spend your time says a lot about you. With Grid, you can connect with people who share your same interests.
          </p>
          <button
            className="download-app-button no-select"
            onClick={() => window.open(appStoreUrl, "_blank")}
          >
            <img
              id="AppStoreButton"
              src={GridDownloadOnTheAppStore}
              alt="Download in the app store"
            />
          </button>
        </div>
      </div>

      <div className="section1-sub2-wrapper">
        <div className="section1-image-wrapper">
          <div className="section1-image">
            <img
              id="section1-sub2-image"
              src={randomImage}
              alt="Want to go on a date here, so does ..."
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section1;
