import { useState } from 'react';

export default function useToken() {
    //TODO: To keep them secure, you should always store JWTs inside an httpOnly cookie. https://blog.logrocket.com/jwt-authentication-best-practices/#:~:text=To%20reiterate%2C%20whatever%20you%20do,JWTs%20inside%20an%20httpOnly%20cookie.
    const getToken = () => {
        const tokenString = sessionStorage.getItem('token');
        return tokenString ? JSON.parse(tokenString) : undefined;
    };

    const [token, setToken] = useState(getToken());
    const saveToken = (userToken:String) => {
        if(!userToken) return;
        sessionStorage.setItem('token', JSON.stringify(userToken));
        setToken(userToken);
    };

    const deleteToken = () => {
        sessionStorage.setItem('token', '');
        setToken('');
    }

    return {
        token: token, 
        setToken: saveToken, 
        deleteToken,
    }
}