import React, { useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import '../../styles/LandingPage.scss'
import Section1 from './Section1/Section1'
import Section2 from './Section2'
import Section3 from './Section3'
import ReactGA from "react-ga4";
const LandingPage = () => {
  
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  return (
    <div className="landing-page-wrapper">
      <Section1/>
      <Section2/>
    </div>
  );
}
export default LandingPage;
