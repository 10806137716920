import React, { useState } from "react";
import { Container, Row, Col, Button } from "reactstrap";
import { Link } from "react-router-dom";
import GridIcon from "../static/assets/grid-pin-icon.png";
import GridLogo from "../static/assets/grid-text-logo.png";
import InstaIcon from "../static/assets/instagram-icon.png";
import TiktokIcon from "../static/assets/tiktok-icon.png";
import LinkedinIcon from "../static/assets/linkedin-icon.png";
import MenuIcon from "../static/assets/menu-icon.png";

import "../styles/Header.scss";
import { userContext } from "./context/userContext";

const Header = () => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  return (
    <div className="header-container">
      <div className="menu-icon-wrapper" onClick={toggleDropdown}>
        <img className="menu-icon" src={MenuIcon} alt="Logo Image" />
      </div>

      <div className="logo-wrapper">
        <img className="logo-image" src={GridLogo} alt="Logo Image" />
      </div>

      <div className="nav-wrapper">
        <Link className="nav-link" to="/">
          Home
        </Link>
        <Link className="nav-link" to="/support">
          Support
        </Link>
      </div>

      <div className="social-link-wrapper">
        <a
          className="social-link"
          href="https://www.instagram.com/griddating/"
        >
          <img src={InstaIcon} alt="Instagram" />
        </a>
        <a
          className="social-link"
          href="https://www.tiktok.com/@griddating"
        >
          <img src={TiktokIcon} alt="Tiktok" />
        </a>
        <a
          className="social-link"
          href="https://www.linkedin.com/company/grid-dating/about/"
        >
          <img src={LinkedinIcon} alt="Linkedin" />
        </a>
      </div>

      {isDropdownVisible && (
        <div className="dropdown-menu">
          <Link className="dropdown-link" to="/" onClick={toggleDropdown}>
            Home
          </Link>

          <div className="divider" />

          <Link
            className="dropdown-link"
            to="/support"
            onClick={toggleDropdown}
          >
            Support
          </Link>
        </div>
      )}
    </div>
  );
};

export default Header;
