import React from "react";

import "../../styles/Policy.scss";

const CommunityGuidlines = () => {
  return (
    <div className="privacy-policy-wrapper">
      <div className="privacy-policy">
        <h1 className="privacy-policy-title">Community Guidelines for Grid</h1>

        <p>
          Welcome to Grid! Our community is built on mutual respect and a
          commitment to creating a positive and enjoyable experience for all
          users. To ensure that everyone feels comfortable and safe, we've
          established the following Community Guidelines. Please read them
          carefully and abide by these rules while using our app.
        </p>

        <h2 className="section-title">1. Be Respectful</h2>
        <div className="section">
          <p>
            <strong>Treat Others as You Want to be Treated:</strong>
          </p>
          <p>
            - Always communicate with kindness and respect. Treat other users as
            you would like to be treated.
          </p>
          <p>
            <strong>No Harassment or Bullying:</strong>
          </p>
          <p>
            - Harassment, bullying, or any form of abusive behavior is strictly
            prohibited. Be considerate of others' feelings and boundaries.
          </p>
        </div>

        <h2 className="section-title">2. Keep it Safe</h2>
        <div className="section">
          <p>
            <strong>Personal Safety First:</strong>
          </p>
          <p>
            - Prioritize your personal safety. Avoid sharing personal
            information, such as your address or financial details, with
            strangers.
          </p>
          <p>
            <strong>Report Suspicious Activity:</strong>
          </p>
          <p>
            - If you encounter any suspicious or inappropriate behavior, report
            it immediately. Your safety is our top priority.
          </p>
        </div>

        <h2 className="section-title">3. Appropriate Content</h2>
        <div className="section">
          <p>
            <strong>No Nudity or Explicit Content:</strong>
          </p>
          <p>
            - Keep your profile and interactions free from nudity, explicit
            content, or any material that violates our guidelines.
          </p>
          <p>
            <strong>Respect Copyright and Trademarks:</strong>
          </p>
          <p>
            - Only post content that you have the right to share. Respect the
            intellectual property of others.
          </p>
        </div>

        <h2 className="section-title">4. Authenticity Matters</h2>
        <div className="section">
          <p>
            <strong>Use Genuine Photos:</strong>
          </p>
          <p>
            - Represent yourself authentically by using recent and accurate
            photos in your profile.
          </p>
          <p>
            <strong>No Impersonation:</strong>
          </p>
          <p>
            - Do not impersonate or create fake profiles. Be genuine in your
            interactions.
          </p>
        </div>

        <h2 className="section-title">5. Respect Privacy</h2>
        <div className="section">
          <p>
            <strong>Obtain Consent:</strong>
          </p>
          <p>
            - Respect others' privacy. Obtain consent before sharing personal
            information or private photos.
          </p>
          <p>
            <strong>No Unsolicited Sharing:</strong>
          </p>
          <p>
            - Do not share other users' personal information without their
            explicit consent.
          </p>
        </div>

        <h2 className="section-title">6. Follow App Policies</h2>
        <div className="section">
          <p>
            <strong>Obey App Store Guidelines:</strong>
          </p>
          <p>- Follow the guidelines set by app stores to ensure compliance.</p>
        </div>

        <h2 className="section-title">7. Report and Support</h2>
        <div className="section">
          <p>
            <strong>Report Violations:</strong>
          </p>
          <p>
            - If you come across content or behavior that violates our
            guidelines, use the reporting feature to bring it to our attention.
          </p>
          <p>
            <strong>Support Each Other:</strong>
          </p>
          <p>
            - Encourage a positive community by supporting fellow users and
            reporting inappropriate behavior.
          </p>
        </div>

        <p>
          Thank you for being a part of the Grid community! By following these
          guidelines, you contribute to creating a welcoming and enjoyable
          environment for everyone.
        </p>
        <p>
          For any questions or concerns, contact our support team at
          support@dateongrid.com.
        </p>
      </div>
    </div>
  );
};

export default CommunityGuidlines;
